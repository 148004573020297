import { render, staticRenderFns } from "./StarSadali2.vue?vue&type=template&id=234fe1c0&scoped=true&"
import script from "./StarSadali2.vue?vue&type=script&lang=js&"
export * from "./StarSadali2.vue?vue&type=script&lang=js&"
import style0 from "./StarSadali2.vue?vue&type=style&index=0&id=234fe1c0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "234fe1c0",
  null
  
)

export default component.exports